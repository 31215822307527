// extracted by mini-css-extract-plugin
export var defaultContainer = "projects-details-module--default-container--UtbE1";
export var galleryContainer = "projects-details-module--gallery-container--FCJP0";
export var licenseContainer = "projects-details-module--license-container--eG0fT";
export var licenseKey = "projects-details-module--license-key--e-tDy";
export var licenseValue = "projects-details-module--license-value--10-Vb";
export var pageContainer = "projects-details-module--page-container--mir8Y";
export var projectTag = "projects-details-module--project-tag--JYTRA";
export var projectTags = "projects-details-module--project-tags--w0VTh";
export var releasesContainer = "projects-details-module--releases-container--fgIrU";
export var repositoryContainer = "projects-details-module--repository-container--PnjvB";